import { graphql } from "gatsby"
import TagsComponent from "../components/tags"

export default TagsComponent

export const query = graphql`
  query {
    allContentfulPerfectPost(sort: { fields: tags, order: DESC }) {
      group(field: tags) {
        fieldValue
        totalCount
      }
    }
  }
`
