import * as React from "react"
import Tags from "../../../components/tags"

type Props = {
  data: {
    allContentfulPerfectPost: {
      group: {
        fieldValue: string
        totalCount: number
      }[]
    }
  }
  [key: string]: any
}

export default function MinimalBlogCoreTags({ ...props }: Props) {
  const {
    data: { allContentfulPerfectPost },
  } = props

  return <Tags list={allContentfulPerfectPost.group} {...props} />
}
